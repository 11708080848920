<template>
    <HeaderMobile :head_menu_items="head_menu_items"></HeaderMobile>
    <header>
      <v-row class="justify-center ">
        <v-col cols="12" sm='10'> 
          
              
              <v-row class = " d-none d-sm-flex py-4 mt-0 flex-nowrap"  no-gutters>
                <v-col  sm='4' md="3" lg='3' class='align-center pr-4'>
                 
                  <v-img  max-width='60%' src='@/assets/img/logo.png' @click = "this.$router.push('/')" class="logo"></v-img>
                </v-col>
                <v-col sm='6'  class='header-nav d-flex justify-end align-center'>
                  <HeadNavigationMenu class="d-flex" :items="head_menu_items"></HeadNavigationMenu>
                </v-col>
                
              </v-row>
        </v-col> 
      </v-row>
    </header>
  </template>
  
  <script>
  import HeaderMobile from '@/components/HeaderMobile.vue'
  import HeadNavigationMenu from '@/components/HeadNavigationMenu.vue'
  export default {
    name: 'Header',
    components: {
      HeadNavigationMenu,
      HeaderMobile
  },
    props:{
      ch_theme: String,
      cart_q: Number,
    },
    data: () => ({
      head_menu_items: [
      {
        name:'About',
        link: '/about'
      },
      {
        name:'Contacts',
        link: '/contacts'
      },
      {
        id:'cart',
        name:'Cart',
        link: '/cart'
      },
      
      ],
      
      
    }),

    mounted(){
      if(this.cart_q != undefined){
          var result = this.head_menu_items.filter(function(item, index, array) {
            if(item.id == 'cart'){
              return true
            }
          })
      //Добавляем количество элементов к названию корзины
      
        result[0].name = result[0].name + "(" + this.cart_q + ")"
      }
    },
    watch:{
      cart_q(){
        if(this.cart_q != undefined){
          var result = this.head_menu_items.filter(function(item, index, array) {
            if(item.id == 'cart'){
              return true
            }
          })
      //Добавляем количество элементов к названию корзины
      
        result[0].name = "Cart" + "("+this.cart_q+")"
      }
      }
    }
    
  
  
}
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  .header-nav{
      font-family: "Roboto", sans-serif; 
      font-weight: 400;
      font-size: 18px;
      
  }
  
  .header-nav .v-btn{
      text-transform: none;
      font-size: 18px !important;	
      padding: 0 6px !important;
      height: 20px;
      font-weight: 400;
  }



  .logo{
    cursor:pointer;
  }
  
  .head_moile_menu .v-btn .v-btn__content{
      font-family: futura-pt !important;
      font-weight: 500 !important;
      font-style: normal !important;
      font-size: 34px !important;
      letter-spacing: 0em !important;
      line-height: 1.2em !important;
      text-transform: none !important;
      color: #333 !important;
      
  }



  </style>
  
