<template>
  <v-app>
    <div v-if="loading">
      <v-row class="flex-column" style="height:100vh">
        <v-col class=" d-flex justify-center align-end"><v-img max-width='30%' max-height="30%" src='@/assets/img/logo.png' @click = "this.$router.push('/')" class="logo"></v-img></v-col>
        <v-col class="d-flex justify-center text-h4 text-bold">Loading...</v-col>
      </v-row>
    </div>

    <div v-if="post">
      <Header :cart_q="cart_products.length"></Header>
      <div class = "content_block pt-4 d-flex flex-column">
        <router-view/>
        <Footer></Footer>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
      Header,
      Footer
  },
  data: () => ({
    post: false,
    loading: false,
    s_d:{},
    cart_products:[],
      products:{},
      /*
        'fc3d3610-4a9a-496f-801c-6ae2ef657cfb':{
          name: "Snorry Trion",
          price: "3490",
          title: "Flagship open-back planar magnetic headphone",
          main_img:0,
          
          imgs:[
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t1m.jpg',
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t2m.jpg',
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t3m.jpg',
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t4m.jpg',
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t5m.jpg',
            'assets/img/fc3d3610-4a9a-496f-801c-6ae2ef657cfb/t6m.jpg'
          ]
        },
        '3f8a5250-eb66-41d4-bb56-9ee860199e37':{
          name: "Snorry SI-6M",
          price: "1290",
          title: "Open-back planar magnetic headphone",
          
          main_img:0,
          imgs:[
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m1.jpg',
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m2.jpg',
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m3.jpg',
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m4.jpg',
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m5.jpg',
            'assets/img/3f8a5250-eb66-41d4-bb56-9ee860199e37/si6m6.jpg'
          ],
          
        },
        'a7556f47-7fd1-4208-bb25-8a330f3b5efe':{
          name: "Earpads for SI-5 /SI-6",
          price: "5",
          title: "Replacement leather ear pads for Snorry SI-5 & SI-6",
          specifications:[Material:'Genuine Sheep Leather',],
          main_img:0,
          imgs:[
            'assets/img/a7556f47-7fd1-4208-bb25-8a330f3b5efe/w1_1.jpg',
            'assets/img/a7556f47-7fd1-4208-bb25-8a330f3b5efe/w2.jpg',
            'assets/img/a7556f47-7fd1-4208-bb25-8a330f3b5efe/w3.jpg'
          ],
          
        },

      },*/
    cart_q: 0,
  }),
  methods:{
    getServerData(){
      this.loading = true;
      this.axios
      .get('https://api.snorry.org/products')
      .then(response => {
        this.$root.products = response.data.result;
        this.post= true;
        this.loading = false;
        console.log(this.$root.products)
      });
    }
    
  },
  created(){
    
     this.getServerData();
  },
  mounted(){
    if (localStorage.cart_products) {
      this.cart_products = JSON.parse(localStorage.cart_products);
    }
    console.log(this.$root.products)
  },
  watch: {
    cart_products: {
      handler(newValue, oldValue) {
          localStorage.cart_products = JSON.stringify(newValue);;
      },
      deep: true
    }
  }
}
</script>
<style>

@font-face {
font-family: 'proxima-nova';
src: local('Proxima Nova Light'), local('Proxima-Nova-Light'),
    url('@/assets/fonts/ProximaNova-Light.woff2') format('woff2'),
    url('@/assets/fonts/ProximaNova-Light.woff') format('woff'),
    url('@/assets/fonts/ProximaNova-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}

@font-face {
    font-family: 'futura-pt';
    src: local('Futura PT Book'), local('Futura-PT-Book'),
        url('@/assets/fonts/FuturaPT-Book.woff2') format('woff2'),
        url('@/assets/fonts/FuturaPT-Book.woff') format('woff'),
        url('@/assets/fonts/FuturaPT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @media (max-width: 600px){
    #app {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#app::-webkit-scrollbar {
  width: 0;
  height: 0;
}
    .content_block{
      max-height: calc(100vh - 64px);
      overflow-y: auto;
      overflow-x:hidden;
      min-height: calc(100vh - 64px);
    }
  }
  .page_name{
  
  font-family: futura-pt !important;
  font-weight: 100 !important;
  font-style: normal;
  font-size: 32px !important;
  letter-spacing: -.03em !important;
  line-height: 1.3em;
  text-transform: none !important;
      

}

</style>