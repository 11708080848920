<template>
    <div class = "HeadNavigationMenu">
        <div 
            class = "head-navigation-menu-elem d-flex"
            v-for="(item, key) in items"
            :key ='key'
            >
            <v-btn 
                class="px-1"
                text
                small
                elevation = '0'
            
                @click = "goToLink(item.link)"
            >
                {{item.name}}
            </v-btn>
        </div>
    </div>
</template>


<script>

export default {
  name: 'HeadNavigationMenu',
    props:{
        items: Array,
        mobile: Boolean,
    },
    data: () => ({
   
   
    
    }),
    methods:{
    goToLink(link){
      this.$router.push(link)
    }
  }
}
</script>