<template>
    <v-row class="justify-center" no-gutters>
        <v-col col="12" sm="10">
            <v-row class="main-product-list px-n4">
                <v-col v-for="product, index in products" :key = 'index' cols = '6' md='3' class='align-center px-4'>
                    <v-card elevation = '0' @click = "route(index, product.clicable)">
                        <v-img max-width='100%' :src="product.imgs[product.main_img]"></v-img>
                        <div class='product_name'>{{ product.name }} </div>
                        <div class='product_price'>{{"$" + product.price}}</div>
                        
                    </v-card>
                </v-col>
               
            </v-row>
        </v-col>
    </v-row>



</template>

<script>

export default {
name: 'Content',
components: {

},
data: () => ({ 
    products: {}

}),

methods:{
   
    route(id, clicable){
    
          if(clicable != false){
            this.$router.push('/products/'+id)
          }
        
    }
},
mounted(){
      
      this.products = this.$root.products
      console.log(this.$root.products);
    }
}
</script>

<style>

.product_name{
font-family: futura-pt;
font-weight: 700;
font-style: normal;
font-size: 15px;
letter-spacing: 0em;
line-height: 1.5em;
text-transform: none;
text-align: center;

}

img{
    pointer-events: none;
}
.product_price{
margin: 0.5em 0 0 0;
font-family: futura-pt;
font-weight: 400;
font-style: normal;
font-size: 13px;
letter-spacing: 0em;
line-height: 0em;
text-transform: none;
text-align: center;
height: 20px;
min-height: 20px;
}

.main-product-list .v-card{
cursor: pointer;
}

@media (max-width: 600px){

#wrapper .v-application--wrap{
height: 100vh;
}

html::-webkit-scrollbar {
display: none; /*chrome*/
}

#content{
overflow-y:auto;
}


}
</style>