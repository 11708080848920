<template>
    <v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#content"
      class="d-sm-none pb-0 "
      >
        <v-row class = "d-flex py-4 px-3 d-sm-none justify-space-between flex-nowrap">
           <v-col cols='4' class='align-center'>
          
             <v-img 
                

                class="logo"
                max-width='176px' 
                src='@/assets/img/logo.png'
                @click = "this.$router.push('/')"
             
             ></v-img>
           </v-col>
           <v-col class = "d-flex d-sm-none align-center justify-end">
            <HeadNavigationMenu class="d-flex" :items="head_menu_items"></HeadNavigationMenu>
             <!--<v-btn
                 elevation="0"
                 icon
                 @click = "show = !show"
             >
               <v-icon>mdi-menu</v-icon>
             </v-btn>-->
         </v-col>
         </v-row>  
           </v-app-bar>
    <div class = "pt-16 d-sm-none"></div>

         <v-navigation-drawer
         class="head_mobile_menu"
           v-model="show"
           absolute
           left
           temporary
           width="100%"
         >
         <v-row class = "justify-end">
          
           <!--<v-btn class='ma-5 mb-0' plain
                 elevation="0"
                 icon
                 @click = 'show = false'
               >
                
               <v-icon>mdi-close</v-icon>
           </v-btn>-->
         </v-row>
           <v-row class="px-4 py-4 pt-0" no-gutters>
             
            <HeadNavigationMenu :items="head_menu_items"></HeadNavigationMenu>
    
           </v-row>
         </v-navigation-drawer>
</template>

<script>
import HeadNavigationMenu from '@/components/HeadNavigationMenu.vue'
export default {
  name: 'HeaderMobile',
  components: {
    HeadNavigationMenu
  },
  props:{
    head_menu_items: Array,
    show_menu: Boolean,
  },
  data: () => ({
    show: false,
    logo_img: 'assets/img/logo.png',
  }),
  computed(){
    this.show = show_menu

  },


    
  }



</script>

<style>


.head_mobile_menu .v-btn{
	height: 50px !important;
	margin-bottom: 20px !important;
}
.head_mobile_menu .v-btn .v-btn__content{
	font-family: futura-pt !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 34px !important;
    letter-spacing: 0em !important;
    line-height: 1.2em !important;
    text-transform: none !important;
    color: #333 !important;
	
}

.v-navigation-drawer{
    z-index: 1008 !important;
    top: 0 !important;
    height: 100vh !important;
    width: 100vw !important;
}




</style>